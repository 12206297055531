const admin_urls = {
  dashboard: {
    getUserCenter: "/zone/centers",
    getAllUser: "/zone/candidates",
    getUserSuspects: "/zone/suspects",
    candidateCount: "/zone/candidates/count",
    submittedCandidate: "/zone/candidates/submitted",
    getUserDetailsById: "/zone/candidate",
    getExamDetails:"",
     getExaminationDetails:"",
     getCenterDetails:"/zone/center/details",
  },
};

export default admin_urls;
 