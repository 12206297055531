import { Dropdown, Table } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { GoPrimitiveDot } from 'react-icons/go';
import { SiLogstash } from "react-icons/si"
import moment from "moment"
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface ILiveUserTable {
    tableData: any,
}

const UserDetailsTable = ({ tableData }: ILiveUserTable) => {

    const navigate = useNavigate();

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    SR.No
                </Table.HeadCell>
                <Table.HeadCell>
                    Email
                </Table.HeadCell>
                <Table.HeadCell>
                    CenterId
                </Table.HeadCell>
                <Table.HeadCell>
                    Exam Id
                </Table.HeadCell>
                <Table.HeadCell>
                    ExamMapping Id
                </Table.HeadCell>
                <Table.HeadCell>
                    Active
                </Table.HeadCell>
                <Table.HeadCell>
                    Dev info
                </Table.HeadCell>
                <Table.HeadCell>
                    Status
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
                    return (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                <span className="flex items-center">
                                    {index + 1}
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.email}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.centerId}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.examId}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.examMappingId}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.isActive ? "True" : "False"}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.devInfo}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.status}
                            </Table.Cell>
                        </Table.Row>
                    )
                }) : "No data found"}
            </Table.Body>
        </Table>
    )
}

export default UserDetailsTable