import makeRequest, { RequestMethod } from "../make.request";
import urls from "../urls";
import admin_urls from "./Admin/admin.urls";

export default class AdminService {
    static async getUserCenter() {
        return await makeRequest(admin_urls.dashboard.getUserCenter, RequestMethod.GET)
    }

    static async getAllUsers(center: any) {
        return await makeRequest(admin_urls.dashboard.getAllUser + "?center=" + center, RequestMethod.GET)
    }

    static async getUserSuspects() {
        return await makeRequest(admin_urls.dashboard.getUserSuspects, RequestMethod.GET)
    }

    static async getCandidateCount() {
        return await makeRequest(admin_urls.dashboard.candidateCount, RequestMethod.GET)
    }

    static async getSubmittedCandidate() {
        return await makeRequest(admin_urls.dashboard.submittedCandidate, RequestMethod.GET)
    }

    static async getUserDetailsbyId(id: any) {
        return await makeRequest(admin_urls.dashboard.getUserDetailsById + "/" + id, RequestMethod.GET)
    }

    static async getExamDetails() {
        return await makeRequest(admin_urls.dashboard.getExamDetails, RequestMethod.GET)
    }

      static async getCenterDetails() {
        return await makeRequest(admin_urls.dashboard.getCenterDetails, RequestMethod.GET)
    }

      static async getExamationDetails(center: any) {
        return await makeRequest(admin_urls.dashboard.getExaminationDetails + "?center=" + center, RequestMethod.GET)
    }

}
