import { useTable, useSortBy } from 'react-table';
import { ICenter } from '../../views/Admin/Dashboard/CenterDetails';

interface IData {
  data: ICenter[];
  columns: any;
}

const CenterDetailsTable = ({ data, columns }: IData) => {
  const table = useTable({ data, columns }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table;

  const renderTableHeader = (headerGroup: any) => (
    <tr {...headerGroup.getHeaderGroupProps()} className="text-left uppercase font-bold">
      {headerGroup.headers.map((column: any) => (
        <th
          {...column.getHeaderProps(column.getSortByToggleProps())}
          className={`px-6 py-3 border-b border-gray-300 font-smallest text-sm bg-gray-200`}
        >
          {column.render('Header')}
          <span className="ml-2">
            {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
          </span>
        </th>
      ))}
    </tr>
  );

  return (
    <table {...getTableProps()} className="w-[89%] whitespace-no-wrap bg-white border-collapse table-auto m-4 rounded-lg overflow-hidden">
  <thead>{headerGroups.map(renderTableHeader)}</thead>
  <tbody {...getTableBodyProps()}>
    {rows.length > 0 ? rows.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()} className="border border-gray-200 hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap font-smallest">{row.index + 1}</td>
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap font-smallest">
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    }) : <tr className="border border-gray-200 hover:bg-gray-50">
            <td className="px-6 py-4 whitespace-nowrap font-small">
              No Data Available
            </td>
        </tr>}
  </tbody>
</table>

  );
};

export default CenterDetailsTable;
