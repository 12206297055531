import { Card } from 'flowbite-react'
import React from 'react'
import { TbHandStop } from 'react-icons/tb';
import { RxAvatar } from "react-icons/rx";



interface IStudentCard {
  type?: any,
  text: string,
  count: any
}


const StudentAllCard = ({ text, count, type }: IStudentCard) => {
  return (
    <div className="max-w-sm mx-5">
      <Card
        style={{ width: "14rem" }}
        className="outline-black rounded-2xl border-2 border-gray-100"
      >
        <div className='flex items-center'>
          {type === "submitted" ? <TbHandStop className="text-xl" /> : <RxAvatar className="text-xl" />}

          <h5 className="text-xl tracking-wide text-left align-text-top uppercase font-medium font-semibold ml-2">
            {text}
          </h5>
        </div>
        <div>
          <h5 className="text-xl text-gray-500 tracking-wide text-left font-normal font-semibold">
            {count}
          </h5>
        </div>
      </Card>
    </div>
  );
}

export default StudentAllCard
