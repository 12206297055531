import { Card, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { Column } from "react-table";
import CenterDetailsTable from "../../../components/Tables/centerDetails.table";

import AdminService from "../../../services/private/AdminService";

export interface ICenter {
  id: number;
  subject: string;
  date: string;
  time: string;
  location: string;
}

export const columns:readonly Column<any>[] = [
  { Header: 'Sr. No.', accessor: 'srNo' },
  { Header: 'ID', accessor: 'id' },
  { Header: 'Subject', accessor: 'subject' },
  { Header: 'Date', accessor: 'date' },
  { Header: 'Time', accessor: 'time' },
  { Header: 'Location', accessor: 'location' },
];

type FetchCenterDetails = () => Promise<void>;

const mutableColumns = columns.slice();

const CenterDetails = (props:any) => {

const [isLoading,setIsLoading]=useState<boolean>(false);
const [centerData,setCenterData]=useState<ICenter[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

const fetchCenterDetails: FetchCenterDetails = async () => {
    setIsLoading(true);
    try {
      const res = await AdminService.getCenterDetails();
      if (res?.status === 200) {
        setCenterData(res?.data);
        setIsDataLoaded(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCenterDetails();
  }, []);

  console.log(centerData,'cd');

  return (
    <div className="h-full">
      {isLoading && <div className="flex justify-center items-center h-full"><Spinner/></div>}
     {isDataLoaded && (
        <Card className="mx-5 my-10">
            <h3 className="text-2xl font-medium text-dark-500 dark:text-gray-400">
                Center Details
            </h3>
        <CenterDetailsTable
          data={ Array.isArray(centerData) &&  centerData?.length > 0  ? centerData : []}
          columns={mutableColumns}
        />
        </Card>
      )}
    </div>
  )
}

export default CenterDetails;
