import { Card, Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import LiveUserTable from '../../../components/Tables/liveUser.table';
import SuspectUserTable from '../../../components/Tables/suspectUser.table';
import AdminService from '../../../services/private/AdminService';

const SuspectUser = () => {

    const [suspectData, setSuspectData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const getUserSuspects = async () => {
        setLoading(true);
        await AdminService.getUserSuspects().then((res) => {
            if (res.status === 200) {
                setSuspectData(res.data.candidates);;
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
        setLoading(false);
    }

    useEffect(() => {
        getUserSuspects();
    }, [])
    return (
        <>
            {loading ? <div className="text-center mt-4">
                <Spinner aria-label="Center-aligned spinner example" />
            </div> :
                <Card className="mx-5 my-10">
                    <h3 className="text-2xl font-medium text-dark-500 dark:text-gray-400">
                        Suspect Users
                    </h3>

                    <SuspectUserTable tableData={suspectData} />
                </Card>}
        </>
    )
}

export default SuspectUser