import { Card, Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserDetailsTable from '../../../components/Tables/UserDetails.table';
import AdminService from '../../../services/private/AdminService';

const UserDetails = () => {
    const { userId } = useParams();

    const [details, setDetails] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);


    const getUserDetailsById = async () => {
        setLoading(true);
        await AdminService.getUserDetailsbyId(userId).then((res) => {
            if (res.status === 200) {
                setDetails(res.data);
                setLoading(false);
            }
        }).catch(err => {
            console.log(err);
        })
        setLoading(false)
    }

    useEffect(() => {
        getUserDetailsById();
    }, [])

    return (
        <>
            {loading ? <div className="text-center mt-4">
                <Spinner aria-label="Center-aligned spinner example" />
            </div > :
                <Card className='mx-5 my-10'>
                    <h3 className="text-2xl font-medium text-dark-500 dark:text-gray-400">
                        User Details
                    </h3>
                    <UserDetailsTable tableData={details && details.logs} />
                </Card>}
        </>
    )
}

export default UserDetails