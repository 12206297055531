import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import adminRoutes from '../../router/admin.router';

interface IAdminSideBar {
    render?: any
}

export default function AdminSidebar(props: IAdminSideBar) {

    const active_routes = adminRoutes.filter(route => route.navbarShow === true);
    const location = useLocation();
    const locLen = location.pathname.split('/')[2];
    const navigate = useNavigate()

    return (
        <>
            <div className="flex justify-start">
                <div className="bg-blue-800 h-screen w-fit text-white p-3">
                    {active_routes.map((data) => {
                        return (
                            <div
                                style={{ "cursor": "pointer" }}
                                // onClick={()=>navigate(`/admin/${data.path}`)}
                                className={`${(data.path === locLen) ? "bg-blue-700" : ""} mt-2 rounded p-3`}>

                                <span className={"bg-blue-400"} style={{ fontSize: "20px" }} onClick={() => navigate(`/admin/${data.path}`)}>
                                    <data.icon />
                                </span>
                            </div>
                        )
                    })}
                </div>
                <div className='w-full'>
                    {props.render}
                </div>
            </div>
        </>
    )
}