import React from 'react';
import {  FaChartPie, FaClipboardList, FaStethoscope } from 'react-icons/fa';
import { Card } from 'flowbite-react';


const ExaminationDetail: React.FC<any> = () => {

  // const fetchexaminationData=async()=>{
  //  await AdminService.getExamationDetails()
  // }

  return <div className="w-11/12 ml-6 md:flex md:flex-row">
  <div className="sm:w-full md:w-1/2 mx-auto sm:mx-0 my-10">
      <Card className="mb-6">
        <div className="font-bold mb-4">
          <FaClipboardList className="inline-block mr-2" />
          Pre-examination
        </div>
     <div className="flex flex-col">
  <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Test Centers Pending:</div>
    <div>{}</div>
  </div>
  <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Test Centers Completed:</div>
    <div>{}</div>
  </div>
</div>

      </Card>

      <Card className="mb-6">
        <div className="font-bold mb-4">
          <FaStethoscope className="inline-block mr-2" />
          Examination
        </div>
         <div className="flex flex-col">
  <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Total Candidates:</div>
    <div>{}</div>
  </div>
  <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Appearing Candidates:</div>
    <div>{}</div>
  </div>
   <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Appeared Candidates:</div>
    <div>{}</div>
  </div> <div className="text-gray-500 px-4 py-2">
    <div className="font-small">AAbsent Candidates:</div>
    <div>{}</div>
  </div>
  </div>
      </Card>

      <Card className="">
        <div className="font-bold mb-4">
          <FaClipboardList className="inline-block mr-2" />
          Post-examination
        </div>
        <div className="flex flex-col">
  <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Test Centers Pending:</div>
    <div>{}</div>
  </div>
  <div className="text-gray-500 px-4 py-2">
    <div className="font-small">Test Centers Completed:</div>
    <div>{}</div>
  </div>
  </div>
  
          </Card>
          </div> 
          <div className='sm:w-full md:w-1/2 sm:my-0 md:my-10'>
          <Card className="w-full mx-auto sm:ml-0 md:ml-4">
    <div className="font-bold mb-4">
      <FaChartPie className="inline-block mr-2" />
      General Overview
    </div>
    <div className="flex flex-col justify-between justify-center gap-2 px-4 py-2">
      <div>
        <div className="text-lg font-bold">Total Centers:</div>
        <div className="text-4xl font-bold">{}</div>
      </div>
      <div>
        <div className="text-lg font-bold">Center Login Time:</div>
        <div className="text-4xl font-bold">{}</div>
      </div>
      <div>
        <div className="text-lg font-bold">Center Logout Time:</div>
        <div className="text-4xl font-bold">{}</div>
      </div>
    </div>
  </Card>
  </div>
          </div> 
          }


export default ExaminationDetail;
