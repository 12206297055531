import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import adminRoutes from "../../router/admin.router";

export default function Topbar() {
  const [navigator, setNavigator] = useState<any[]>([]);

  const location = useLocation();
  const navigate = useNavigate();
  // const getNavigations = () => {
  //   let route: any = {};
  //   switch (location.pathname.split("/")[1]) {
  //     case "admin": {
  //       route = adminRoutes.find(
  //         (route) => route.path === location.pathname.split("/")[2]
  //       );
  //     }
  //     // case 'exam': {
  //     //     route = examRoutes.find(route => route.path === location.pathname.split("/")[2])
  //     // }
  //   }

  //   if (route && route.navigator && route.navigator.length > 0) {
  //     setNavigator(route.navigator);
  //   } else {
  //     setNavigator([]);
  //   }
  // };

  const logout = () => {
    localStorage.removeItem("authkey-super");
    navigate('/logout')
  };

  // useEffect(() => {
  //   getNavigations();
  // }, [location.pathname]);
  return (
    <Navbar fluid={true} rounded={true} border={true}>
      <Navbar.Brand>
        <img
          src="https://flowbite.com/docs/images/logo.svg"
          className="mr-3 h-6 sm:h-9"
          alt="Flowbite Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          EXMZNE
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline={true}
          label={
            <Avatar
              alt="User settings"
              img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              rounded={true}
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">Bonnie Green</span>
            <span className="block truncate text-sm font-medium">
              name@flowbite.com
            </span>
          </Dropdown.Header>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>Sign out</Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {navigator.length > 0 &&
          navigator.map((data: any) => {
            return (
              <>
                <Navbar.Link href={data.baseurl + "/" + data.route}>
                  {data.name}
                </Navbar.Link>
              </>
            );
          })}
      </Navbar.Collapse>
    </Navbar>
  );
}
