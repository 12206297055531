import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import StudentActvieCard from "../../../components/Cards/Student.active.card";
import StudentAllCard from "../../../components/Cards/Student.all.card";
import StudentBlockedCard from "../../../components/Cards/Student.blocked.card";
import LiveUserTable from "../../../components/Tables/liveUser.table";
import AdminService from "../../../services/private/AdminService";
import Select from "react-select"
import { resolveSoa } from "dns";
export default function Dashboard() {

    const [centerData, setCenterData] = useState<any>();
    const [centerValue, setCenterValue] = useState<any>("");
    const [userData, setUserData] = useState<any>();
    const [candidateCount, setCandidateCount] = useState<any>();
    const [submittedCount, setSubmittedCount] = useState<any>();

    const getUserCenter = async () => {
        await AdminService.getUserCenter().then((res) => {
            if (res.status === 200) {
                setCenterData(res?.data?.centers?.map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                }))
            }
        })
    }

    const getAllUser = async () => {
        await AdminService.getAllUsers(centerValue).then((res) => {
            if (res.status === 200) {
                setUserData(res.data.candidates);
            }
        })
    }


    const getCandidateCount = async () => {
        await AdminService.getCandidateCount().then((res) => {
            if (res.status === 200) {
                setCandidateCount(res.data);
            }
        })
    }

    const getSubmittedCandidate = async () => {
        await AdminService.getSubmittedCandidate().then((res) => {
            if (res.status === 200) {
                setSubmittedCount(res.data);
            }
        })
    }

    useEffect(() => {
        getUserCenter();
        getAllUser();
        getCandidateCount();
        getSubmittedCandidate();
    }, [centerValue])


    return (
        <>
            <div className="w-full flex justify-evenly items-center my-10 mx-5">
                <StudentAllCard type="all" text="All" count={candidateCount?.count} />
                <StudentAllCard type="submitted" text="Submitted" count={submittedCount?.count} />
                {/* <StudentAllCard type="absent" text="Absent" count="10" /> */}
            </div>
            <div className="flex justify-between px-10">
                <h3 className="text-2xl font-medium text-dark-500 dark:text-gray-400">
                    Live Users
                </h3>
                <Select
                    options={centerData}
                    onChange={(e: any) => setCenterValue(e.value)}
                    className="w-80"
                    placeholder="Select Center"
                />
            </div>
            <Card className="mx-5 my-10">
                <LiveUserTable tableData={userData} />
            </Card>
        </>
    )
}