import IRouter from "../interfaces/IRouter";

import { HiBookOpen } from "react-icons/hi2";
import { AiFillBook, AiFillDashboard, AiOutlinePicCenter } from "react-icons/ai";
import Dashboard from "../views/Admin/Dashboard/Dashboard";
import { HiOutlineVideoCamera } from "react-icons/hi";
import dashboardNavigator from "../admin/dashboard.navigator";
import LiveExamIndex from "../views/Live/Live.index";
import LiveExams from "../views/Admin/Exams/LiveExam";
import LiveExamHome from "../home/LiveExam.home";
import Notificationindex from "../views/Notifications";
import { RiNotification2Fill } from "react-icons/ri";
import SuspectUser from "../views/Admin/Dashboard/SuspectUser";
import UserDetails from "../views/Admin/Dashboard/UserDetails";
import ExaminationDetail from "../views/Admin/Dashboard/ExaminationDetail";
import ExamDetails from "../views/Admin/Dashboard/ExamDetails";
import CenterDetails from "../views/Admin/Dashboard/CenterDetails";
import { FaAudioDescription } from "react-icons/fa";
import { SiStatuspal } from "react-icons/si";

export const baseurl = "/admin/";

const adminRoutes: IRouter[] = [
  {
    path: "dashboard",
    element: <Dashboard />,
    navbarShow: true,
    name: "admin",
    icon: AiFillDashboard,
    navigator: dashboardNavigator,
  },
  {
    path: "dashboard/:userId",
    element: <UserDetails />,
    navbarShow: false,
    name: "",
    icon: AiFillDashboard,
    navigator: dashboardNavigator,
  },
  {
    path: "suspect_user",
    element: <SuspectUser />,
    navbarShow: true,
    name: "Suspect User",
    icon: HiOutlineVideoCamera,
  },
   {
    path: "examination_details",
    element: <ExaminationDetail/>,
    navbarShow: true,
    name: "Examination-Detail",
    icon: SiStatuspal,
  },
  {
    path: "exam_details",
    element: <ExamDetails/>,
    navbarShow: true,
    name: "Exam-Detail",
    icon: AiFillBook,
  },
  {
    path: "center_details",
    element: <CenterDetails/>,
    navbarShow: true,
    name: "Center-Detail",
    icon: AiOutlinePicCenter,
  },
  // {
  //   path: "notifications",
  //   element: <Notificationindex />,
  //   navbarShow: true,
  //   name: "Notifications",
  //   icon: RiNotification2Fill,
  // },
];

export default adminRoutes;
