import admin_urls from "./private/Admin/admin.urls";

const urls = {
  public: {
    auth: {
      login: "/zone/login",
    },
  },
 
};

export default urls;
