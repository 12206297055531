import { Dropdown, Table } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { GoPrimitiveDot } from 'react-icons/go';
import { SiLogstash } from "react-icons/si"
import moment from "moment"
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface ILiveUserTable {
    tableData: any,
}

const SuspectUserTable = ({ tableData }: ILiveUserTable) => {

    const navigate = useNavigate();

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    SR.No
                </Table.HeadCell>
                <Table.HeadCell>
                    Name
                </Table.HeadCell>
                <Table.HeadCell>
                    Category
                </Table.HeadCell>
                <Table.HeadCell>
                    Lab id
                </Table.HeadCell>
                <Table.HeadCell>
                    Alloted Center Id
                </Table.HeadCell>
                <Table.HeadCell>
                    Alloted Center
                </Table.HeadCell>
                <Table.HeadCell>
                    Photo
                </Table.HeadCell>
                <Table.HeadCell>
                    Action
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
                    return (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                <span className="flex items-center">
                                    {index + 1}
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.candidate && data.candidate.studentname}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.candidate && data.candidate.category}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.candidate && data.candidate.labid}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.candidate && data.candidate.alloted_center_id}
                            </Table.Cell>
                            <Table.Cell>
                                {data && data.candidate && data.candidate.alloted_center}
                            </Table.Cell>
                            <Table.Cell>

                                <img src={data?.candidate?.photo} width="80px" height="80px" />

                            </Table.Cell>
                            <Table.Cell>
                                <span
                                    className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                    onClick={() => navigate("/admin/dashboard/" + data?.candidate?.studentid)}
                                >
                                    View
                                </span>
                            </Table.Cell>
                        </Table.Row>
                    )
                }) : "No data found"}
            </Table.Body>
        </Table>
    )
}

export default SuspectUserTable