import { useEffect } from "react";
import { Navigate, Outlet, useNavigate,  } from "react-router";
import Auth from "../Auth";
import AdminIndex from "../views/Admin/Admin.index";

export default function AdminHome() {

    let navigate=useNavigate();

    // useEffect(() => {
    //     if(!Auth.checkAuth()) navigate("/login");
    // })
    
    return (
        <div>
            <AdminIndex render={<Outlet/>} />
        </div>
    )
}