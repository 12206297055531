import { Card, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { Column } from "react-table";
import ExamDetailsTable from "../../../components/Tables/examDetails.table";
import AdminService from "../../../services/private/AdminService";

export interface IExam {
  id: number;
  subject: string;
  date: string;
  time: string;
  location: string;
}

export const columns:readonly Column<any>[] = [
  { Header: 'Sr. No.', accessor: 'srNo' },
  { Header: 'ID', accessor: 'id' },
  { Header: 'Subject', accessor: 'subject' },
  { Header: 'Date', accessor: 'date' },
  { Header: 'Time', accessor: 'time' },
  { Header: 'Location', accessor: 'location' },
];

type FetchExamDetails = () => Promise<void>;

const mutableColumns = columns.slice();

const ExamDetails = (props:any) => {

const [isLoading,setIsLoading]=useState<boolean>(false);
const [examData,setExam]=useState<IExam[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

const fetchExamDetails: FetchExamDetails = async () => {
    setIsLoading(true);
    try {
      const res = await AdminService.getExamDetails();
      if (res?.status === 200) {
        setExam(res?.data);
        setIsDataLoaded(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchExamDetails();
  }, []);


  return (
    <div className="h-full">
      {isLoading && <div className="flex justify-center items-center h-full"><Spinner/></div>}
     {isDataLoaded && (
        <Card className="mx-5 my-10">
            <h3 className="text-2xl font-medium text-dark-500 dark:text-gray-400">
                Exam Details
            </h3>
        <ExamDetailsTable
          data={ Array.isArray(examData) &&  examData?.length > 0  ? examData : []}
          columns={mutableColumns}
        />
        </Card>
      )}
    </div>
  )
}

export default ExamDetails
